import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import Chart from "react-apexcharts";
import currency from "currency-formatter";
import getDateRange from "../../../utils/getDataRange";
import { useApp } from "../../../context/appContext";
import moment from "moment";
import locations from "../../../services/locations";
import DailyActivities from "./DailyActivities";
import ProductPerformance from "./ProductPerformance";
import ClientOrdersPerformance from "./ClientOrderPerformance";
import NeighborhoodChart from "./NeighborhoodChart";
import AverageTicket from "./AverageTicket";
import OrdersMap from "./OrdersMap";
import PeriodHeatMap from "./PeriodHeatMap";
import ClientDailyOrders from "./clientDailyOrders";

const SalesOverview = () => {
  const { state, getData } = useApp();

  const [ordersData, setOrdersData] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [clientsData, setClientsData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [clientChart, setClientChart] = useState(null);
  const [clientSecondary, setClientSecondary] = useState(null);
  const [modalityChartData, setModalityChartData] = useState(null);
  const [bestSelling, setBestSelling] = useState(null);
  const [bestClients, setBestClients] = useState(null);
  const [neighborhoodData, setNeighborhoodData] = useState(null);
  const [ordersCoordinates, setOrderCoordinates] = useState(null);
  const [clientsOrdersByDay, setClientsOrdersByDay] = useState(null);

  const filterOrders = () => {
    let orders;

    const selectedBranch = null;

    const endDate = moment().add(1, "days").format("YYYY-MM-DD");
    const initialDate = moment().subtract(1, "months").format("YYYY-MM-DD");

    //console.log("endDate", endDate);
    //console.log("initialDate", initialDate);

    if (selectedBranch) {
      getData(locations.orders, {
        branch: selectedBranch,

        createdAt: {
          $lte: endDate,
          $gte: initialDate,
        },
        $sort: {
          createdAt: -1,
        },
        $limit: 3000,
      }).then((res) => {
        getData(locations.customers, {
          customerOf: state.currentBranch.company,

          createdAt: {
            $lte: endDate,
            $gte: initialDate,
          },
          $sort: {
            createdAt: -1,
          },
          $limit: 0,
        }).then((client) => {
          //console.log("branch orders", res);
          //console.log("clients data", client);
          orders = res.data;
          setClientsData(client.total);
          generateChart(orders);
          setReportData(orders);
          setOrdersData(orders);
          generateMostSelledItems(orders);
        });
      });
    } else {
      getData(locations.orders, {
        company: state.currentBranch && state.currentBranch.company,
        createdAt: {
          $lte: endDate,
          $gte: initialDate,
        },
        $sort: {
          createdAt: -1,
        },
        $limit: 3000,
      }).then((res) => {
        getData(locations.customers, {
          customerOf: state.currentBranch && state.currentBranch.company,

          createdAt: {
            $lte: endDate,
            $gte: initialDate,
          },
          $sort: {
            createdAt: -1,
          },
          $limit: 0,
        }).then((client) => {
          //console.log("company orders", res);
          //console.log("clients data", client);
          orders = res.data;
          setClientsData(client.total);
          generateChart(orders);
          setReportData(orders);
          setOrdersData(orders);
          generateMostSelledItems(orders);
        });
      });
    }
  };

  useEffect(() => {
    filterOrders();
  }, []);

  useEffect(() => {
    if (ordersData && ordersData.length) {
      generateModalityChart(ordersData);
      setOrderCoordinates(ordersData);
      //set order coordinates

      //setOrderCoordinates
    }
  }, [ordersData]);

  /* useEffect(() => {
    console.log("chartData", chartData);
  }, [chartData]); */

  const totalSales = () => {
    let total = 0;
    reportData &&
      reportData
        .filter((p) => p.status !== 0)
        .map((order) => {
          total += order.amount + order.deliveryFee;
        });
    //console.log("total", total);
    return total;
  };

  /* const clientsDailyOrders = () => {
    const orders = ordersData;
  }; */

  const generateModalityChart = (orders) => {
    const modalityChart = {
      series: [
        ordersData.filter((o) => o.modality === 1).length,
        ordersData.filter((o) => o.modality === 2).length,
      ],
      options: {
        chart: {
          width: "100%",
          height: "100%",
          type: "pie",
        },
        labels: ["Delivery", "Retirada"],
        colors: ["#1e4db7", "#a7e3f4"],
        theme: {
          monochrome: {
            enabled: true,
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5,
            },
          },
        },
        grid: {
          padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
        },
        dataLabels: {
          formatter(val, opts) {
            const name = opts.w.globals.labels[opts.seriesIndex];
            return [name, val.toFixed(1) + "%"];
          },
        },
        legend: {
          show: true,
        },
      },
    };

    //console.log("modality chart data", modalityChart);

    setModalityChartData(modalityChart);
  };

  const generateMostSelledItems = (orders) => {
    let allItems = [];

    let allClientOrders = [];

    let allNeighborhood = [];

    orders.map((order) => {
      order.items.map((item) => allItems.push(item._id));
    });

    orders.map((order) => {
      allClientOrders.push(order.customer._id);
    });

    orders
      .filter(
        (order) =>
          order.address &&
          order.address.neighborhood !== undefined &&
          order.address.neighborhood !== ""
      )
      .map((order) => {
        allNeighborhood.push(
          order.address.neighborhood &&
            order.address.neighborhood
              .toLowerCase()
              .replace("á", "a")
              .replace("é", "e")
              .replace("í", "i")
              .replace("ó", "o")
              .replace("ã", "a")
              .replace("õ", "o")
              .replace("ú", "u")
              .trim()
        );
      });

    //console.log("all clients orders", allClientOrders);

    // console.log("items", allItems);

    /* const occurrences = allItems.reduce(function (acc, curr) {
      return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    }, {}); */

    const occurrences = allItems.reduce(
      (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
      new Map()
    );

    const clientOrdersOccurrences = allClientOrders.reduce(
      (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
      new Map()
    );

    const neighborhoodsOccurrences = allNeighborhood.reduce(
      (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
      new Map()
    );

    //console.log("clientOrdersOccurrences", clientOrdersOccurrences);

    //const sortedOcurrencies = occurrences.sort((a, b) => a.value - b.value);

    const sortedOcurrencies = [...occurrences].sort(function (a, b) {
      return b[1] - a[1];
    });

    const sortedClientOcurrencies = [...clientOrdersOccurrences]
      .filter((o) => o[0] !== undefined)
      .sort(function (a, b) {
        return b[1] - a[1];
      });

    const sortedNeighborhoodOccurrencies = [...neighborhoodsOccurrences]
      .filter((o) => o[0] !== undefined)
      .sort(function (a, b) {
        return b[1] - a[1];
      });

    const top10items =
      sortedOcurrencies.length > 10
        ? sortedOcurrencies.slice(0, 10)
        : sortedOcurrencies;

    const top10Clients =
      sortedClientOcurrencies.length > 10
        ? sortedClientOcurrencies.slice(0, 10)
        : sortedClientOcurrencies;

    const top10Neighborhood =
      sortedNeighborhoodOccurrencies.length > 10
        ? sortedNeighborhoodOccurrencies.slice(0, 10)
        : sortedNeighborhoodOccurrencies;

    //console.log("top10Neighborhood", top10Neighborhood);

    let results = [];
    let clientResults = [];

    //console.log("item", item[1]);

    getData(locations.products, {
      _id: {
        $in: top10items.map((i) => i[0]),
      },
    }).then((res) => {
      let c = 1;
      for (const item of top10items) {
        let editedItem = res.data.filter((i) => i._id === item[0]);

        if (editedItem.length) {
          editedItem[0].position = c;
          editedItem[0].totalSales = item[1];
          results.push(editedItem[0]);
          c++;
        }
      }
      const sortedTop10items = results;

      //console.log("sortedTop10", sortedTop10items);

      setBestSelling(sortedTop10items);

      getData(locations.customers, {
        _id: {
          $in: top10Clients.map((c) => c[0]),
        },
      }).then((customer) => {
        //console.log("found customers", customer);
        let customerPosition = 1;
        for (const client of top10Clients) {
          let editedClient = customer.data.filter(
            (i) => i._id === client[0]
          )[0];

          editedClient.position = customerPosition;
          clientResults.push(editedClient);
          customerPosition++;
        }
        setBestClients(
          clientResults.sort(function (a, b) {
            return b.totalOrders - a.totalOrders;
          })
        );
        setNeighborhoodData(top10Neighborhood);
      });
    });
  };

  const generateChart = (orders) => {
    //CONSIDERAR DATA INICIAL E DATA FINAL

    //setChartData

    const endDate = moment().add(1, "days").format("YYYY-MM-DD");
    const initialDate = moment().subtract(1, "months").format("YYYY-MM-DD");

    const dates = getDateRange(initialDate, endDate);

    //CLIENTS_ORDERS

    const clientEndDate = moment().add(1, "days").format("YYYY-MM-DD");
    const clientInitialDate = moment().subtract(7, "days").format("YYYY-MM-DD");

    const newClientSeries = {
      name: "Clientes novos",
      data: dates.map(
        (date) =>
          orders &&
          orders.filter(
            (p) =>
              moment(p.createdAt).isSame(moment(date), "day") &&
              p.status !== 0 &&
              p.customer.totalOrders &&
              p.customer.totalOrders === 1
          ).length
      ),
    };

    const recurrentClientSeries = {
      name: "Clientes recorrentes",
      data: dates.map(
        (date) =>
          orders &&
          orders.filter(
            (p) =>
              moment(p.createdAt).isSame(moment(date), "day") &&
              p.status !== 0 &&
              p.customer.totalOrders &&
              p.customer.totalOrders > 1
          ).length
      ),
    };

    const clientsDailyOrders = [newClientSeries, recurrentClientSeries];

    console.log("clientsDailyOrders", clientsDailyOrders);

    const series = {
      name: "Pedidos Concluídos",
      data: dates.map(
        (date) =>
          orders &&
          orders.filter(
            (p) =>
              moment(p.createdAt).isSame(moment(date), "day") && p.status !== 0
          ).length
      ),
    };

    const cancelledSeries = {
      name: "Pedidos Cancelados",
      data: dates.map(
        (date) =>
          orders &&
          orders.filter(
            (p) =>
              moment(p.createdAt).isSame(moment(date), "day") && p.status === 0
          ).length
      ),
    };

    const categories = dates.map((date) => {
      return moment(date).format("DD/MM");
    });

    categories.pop();

    //console.log("series", series);
    //console.log("categories", categories);

    const options = {
      grid: {
        show: true,
        borderColor: "transparent",
        strokeDashArray: 2,
        padding: {
          left: 0,
          right: 0,
          bottom: 0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "42%",
          endingShape: "rounded",
          borderRadius: 5,
        },
      },

      colors: ["#1e4db7", "#a7e3f4"],
      fill: {
        type: "solid",
        opacity: 1,
      },
      chart: {
        offsetX: -15,
        toolbar: {
          show: false,
        },
        foreColor: "#adb0bb",
        fontFamily: "'DM Sans',sans-serif",
        sparkline: {
          enabled: false,
        },
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: categories,
      },
    };

    const chartObject = {
      series: [series, cancelledSeries],
      options: options,
    };

    const clientChart = {
      series: clientsDailyOrders,
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        xaxis: {
          categories: categories,
        },
        fill: {
          opacity: 1,
        },
      },
    };

    //console.log("categories.pop", categories.pop());

    const clientSecondaryChart = {
      series: clientsDailyOrders,
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "75%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: true,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: categories,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };

    //console.log("series", series);
    //console.log("cancelled", cancelledSeries);
    setClientChart(clientChart);
    setClientSecondary(clientSecondaryChart);
    setChartData(chartObject);
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          paddingBottom: "0",
        }}
      >
        <CardContent
          sx={{
            paddingBottom: "16px !important",
          }}
        >
          {reportData && reportData.length ? (
            <>
              <Box
                sx={{
                  display: {
                    sm: "flex",
                    xs: "block",
                  },
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: "0",
                    }}
                    gutterBottom
                  >
                    Últimos 30 dias
                  </Typography>
                </Box>

                <Box
                  sx={{
                    marginLeft: "auto",
                    display: "flex",
                    mt: {
                      lg: 0,
                      xs: 2,
                    },
                  }}
                >
                  {/* <Button onClick={() => generateMostSelledItems(ordersData)}>
                    Teste
                  </Button> */}
                </Box>
              </Box>
              <Divider
                sx={{
                  marginTop: "15px",
                  marginBottom: "20px",
                }}
              />
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Typography sx={{ fontWeight: "800", fontSize: "12px" }}>
                      Nº DE PEDIDOS
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "2rem",
                      }}
                    >
                      {reportData && reportData.length}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={{ fontWeight: "800", fontSize: "12px" }}>
                      VALOR VENDIDO*
                    </Typography>
                    <Typography sx={{ fontSize: "2rem" }}>
                      {currency.format(totalSales(), {
                        code: "BRL",
                      })}
                    </Typography>
                    <Typography sx={{ fontSize: "10px" }}>
                      *Não considerando pedidos cancelados
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={{ fontWeight: "800", fontSize: "12px" }}>
                      PEDIDOS CANCELADOS
                    </Typography>
                    <Typography sx={{ fontSize: "2rem" }}>
                      {reportData &&
                        reportData.filter((p) => p.status === 0).length}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={{ fontWeight: "800", fontSize: "12px" }}>
                      CLIENTES*
                    </Typography>
                    <Typography sx={{ fontSize: "2rem" }}>
                      {clientsData}
                    </Typography>
                    <Typography sx={{ fontSize: "10px" }}>
                      *Considerando todas as filiais.
                      <br />O filtro não se aplica a esse caso.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  marginTop: "25px",
                }}
              >
                {chartData && (
                  <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type="line"
                    height="295px"
                  />
                )}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                width: "100%",
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </CardContent>
      </Card>
      <ClientDailyOrders
        clientChart={clientChart}
        clientSecondary={clientSecondary}
      />
      <Grid container>
        <Grid item xs={12} lg={4}>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <AverageTicket
                totalAmount={totalSales()}
                ordersCount={reportData && reportData.length}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <DailyActivities modalityChart={modalityChartData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8}>
          <ProductPerformance bestSelling={bestSelling} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ClientOrdersPerformance bestClients={bestClients} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <NeighborhoodChart modalityChart={neighborhoodData} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <PeriodHeatMap ordersData={reportData} />
        </Grid>

        <Grid item xs={12} lg={12}>
          <OrdersMap ordersData={reportData} />
        </Grid>
      </Grid>
    </>
  );
};

export default SalesOverview;
