import React, { useState, useEffect, useCallback } from "react";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  MiniMap,
  Controls,
} from "@xyflow/react";

import {
  Dialog,
  Typography,
  Select,
  Box,
  MenuItem,
  TextField,
  Grid,
  Alert,
  Snackbar,
  Button,
} from "@mui/material";

import "@xyflow/react/dist/style.css";

import ColorSelectorNode from "./ColorSelectorNode";

const initBgColor = "#c9f1dd";

const snapGrid = [20, 20];
const nodeTypes = {
  selectorNode: ColorSelectorNode,
};

const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

const WeFlow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [bgColor, setBgColor] = useState(initBgColor);

  const messagesModel = [
    {
      id: 1,
      type: "email",
      title: "Ação email marketing 01",
      message:
        "Olá, {client_name}! Estamos com uma promoção impordível. Confira nossas {offers}",
    },
    {
      id: 2,
      type: "whatsapp",
      title: "Ação WhatsApp 01",
      message:
        "Olá, {client_name}! Estamos com uma promoção impordível. Confira nossas {offers}",
    },
    {
      id: 3,
      type: "sms",
      title: "Ação SMS 01",
      message:
        "Olá, {client_name}! Estamos com uma promoção impordível para esse número. Confira nossas {offers}",
    },
    {
      id: 4,
      type: "whatsapp",
      title: "Ação WhatsApp - Fim de semana",
      message: "Olá, {client_name}! Somente nesse fim de semana!!! {offers}",
    },
  ];

  const [items, setItems] = useState("");

  const [widgetsDialog, setWidgetsDialog] = useState(false);

  const [triggersDialog, setTriggersDialog] = useState(false);

  const [actionType, setActionType] = useState(null);

  const [selectedMessage, setMessage] = useState(null);

  const [triggerType, setTriggerType] = useState(null);

  const [interationType, setInterationType] = useState(null);

  const [runnerJson, setRunnerJson] = useState([]);

  const [campaingTitle, setCampaingTitle] = useState(null);

  const handleOpen = () => {
    setWidgetsDialog(true);
  };

  const handleClose = () => {
    setWidgetsDialog(false);
    setActionType(null);
    setMessage(null);
  };

  const handleTriggersOpen = () => {
    setTriggersDialog(true);
  };

  const handleTriggersClose = () => {
    setTriggersDialog(false);
  };

  /*   const onConnect = (params) => {
    console.log(params);
    setItems((els) => addEdge(params, els));
  };
 */
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  const addNode = () => {
    console.log("adicionando node");

    setNodes([
      ...nodes,
      {
        id: (nodes.length + 1).toString(),
        data: { label: <div>Sim</div> },
        position: { x: 100, y: 200 },
      },
      {
        id: (nodes.length + 2).toString(),
        data: { label: <div>Não</div> },
        position: { x: 250, y: 200 },
      },
    ]);
  };

  const handleJson = () => {
    console.log("do nothing");
  };

  const addInterval = () => {
    console.log("adicionando node");

    setNodes([
      ...nodes,
      {
        id: (nodes.length + 1).toString(),
        data: {
          label: (
            <div>
              <Select defaultValue="campanha1" style={{ width: 120 }}>
                <MenuItem value="campanha1">Aguardar 12h</MenuItem>
                <MenuItem value="campanha2">Aguardar 24h</MenuItem>
                <MenuItem value="campanha3">Aguardar 72h</MenuItem>
              </Select>
            </div>
          ),
        },
        position: { x: 100, y: 150 },
      },
    ]);
    handleJson({
      type: "interval",
      interval: 43200,
      intervalType: "seconds",
    });
  };

  const addInteraction = () => {
    console.log("adicionando node");

    setNodes([
      ...nodes,
      {
        id: (nodes.length + 1).toString(),
        data: {
          label: (
            <div>
              <Select style={{ width: 120 }}>
                <MenuItem value="read">Visualizou</MenuItem>
                <MenuItem value="answered">Respondeu</MenuItem>
                <MenuItem value="clicked">Clicou no link</MenuItem>
              </Select>
            </div>
          ),
        },
        position: { x: 100, y: 150 },
      },
    ]);
    handleJson({
      type: "interation",
      interationType: "link_cliked",
    });
  };

  const addTrigger = () => {
    console.log("adicionando node");

    setNodes([
      ...nodes,
      {
        id: (nodes.length + 1).toString(),
        data: {
          label: <div>Cliente que compraram nos últimos</div>,
        },
        position: { x: 200, y: 250 },
      },
    ]);
    handleJson({
      type: "trigger",
      triggerType: triggerType,
      triggerValue: 15,
    });
    handleTriggersClose();
  };

  const addAction = () => {
    console.log("adicionando node");

    const selectedAction = messagesModel.filter(
      (m) => m.id === selectedMessage
    );

    if (selectedAction.length) {
      setItems([
        ...nodes,
        {
          id: (nodes.length + 1).toString(),
          data: {
            label: <div>{selectedAction[0].title}</div>,
          },
          position: { x: 100, y: 150 },
        },
      ]);
      handleJson({
        type: "action",
        actionTarget: selectedAction[0].type,
        message: selectedAction[0].title,
      });
      handleClose();
    }
  };

  useEffect(() => {
    const onChange = (event) => {
      console.log("changing");

      setNodes((nds) =>
        nds.map((node) => {
          if (node.id !== "2") {
            return node;
          }

          const color = event.target.value;

          setBgColor(color);

          return {
            ...node,
            data: {
              ...node.data,
              color,
            },
          };
        })
      );
    };

    setNodes([
      {
        id: "1",
        data: { label: "Iniciar campanha" },
        position: { x: 250, y: 5 },
      },
    ]);

    setEdges([
      /* {
        id: "e1-2",
        source: "1",
        target: "2",
        animated: true,
      },
      {
        id: "e2b-4",
        source: "2",
        target: "4",
        sourceHandle: "b",
        animated: true,
      }, */
    ]);
  }, []);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({ ...params, animated: true }, eds)),
    []
  );
  return (
    <>
      <Dialog
        title="Selecione um gatilho"
        open={triggersDialog}
        onClose={handleTriggersClose}
        onOk={() => addTrigger()}
        cancelText="Fechar"
      >
        <Box
          sx={{
            width: "300px",
            padding: "25px",
          }}
        >
          <Select
            style={{ width: "100%", marginTop: "1rem" }}
            onChange={(value) => {
              console.log(value);
              setTriggerType(value);
            }}
          >
            <MenuItem value="purchased_interval">
              Clientes que compraram nos últimos
            </MenuItem>

            <MenuItem value="purchased_number">
              Clientes que fizeram X pedidos
            </MenuItem>
          </Select>
          {triggerType !== null && (
            <p>
              <TextField type="text" fullWidth />
            </p>
          )}
          <Box>
            <Button variant="contained" fullWidth onClick={() => addTrigger()}>
              Ok
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        title="Selecione uma ação"
        open={widgetsDialog}
        onClose={handleClose}
        //onOk={() => addAction()}
        cancelText="Fechar"
      >
        <Box
          sx={{
            padding: "25px",
            textAlign: "center",
          }}
        >
          <Button
            style={{ marginRight: "1rem" }}
            onClick={() => setActionType("email")}
            fullWidth
          >
            Enviar email
          </Button>
          <Button onClick={() => setActionType("sms")} fullWidth>
            Enviar SMS
          </Button>
          <Button onClick={() => setActionType("whatsapp")} fullWidth>
            Enviar WhatsApp
          </Button>
          {actionType !== null && (
            <>
              <Select
                style={{ width: "100%", marginTop: "1rem" }}
                onChange={(value) => {
                  console.log(value);
                  setMessage(value);
                }}
              >
                {messagesModel
                  .filter((m) => m.type === actionType)
                  .map((message) => {
                    return (
                      <MenuItem value={message.id}>{message.title}</MenuItem>
                    );
                  })}
              </Select>
              <div className="messagePreview">
                {selectedMessage !== null &&
                  messagesModel
                    .filter((m) => m.id === selectedMessage)
                    .map((mes) => {
                      return mes.message;
                    })}
              </div>
            </>
          )}
          <Box>
            <Button fullWidth variant="contained" onClick={() => addAction()}>
              Ok
            </Button>
          </Box>
        </Box>
      </Dialog>
      <div className="lateral-panel">
        <Button
          style={{ marginRight: "1rem" }}
          onClick={() => handleTriggersOpen()}
          variant="outlined"
        >
          Adicionar gatilho
        </Button>
        <Button
          style={{ marginRight: "1rem" }}
          variant="outlined"
          onClick={() => handleOpen()}
        >
          Adicionar ação
        </Button>
        <Button
          style={{ marginRight: "1rem" }}
          variant="outlined"
          onClick={() => addNode()}
        >
          Adicionar condição
        </Button>
        <Button
          onClick={() => addInteraction()}
          variant="outlined"
          style={{ marginRight: "1rem" }}
        >
          Adicionar interação
        </Button>
        <Button
          style={{ marginRight: "1rem" }}
          variant="outlined"
          onClick={() => addInterval()}
        >
          Adicionar intervalo
        </Button>
        <Button onClick={() => getFinalJson()} variant="contained">
          Salvar
        </Button>
      </div>
      <div style={{ width: "100%", height: "900px" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          snapToGrid={true}
          snapGrid={snapGrid}
          defaultViewport={defaultViewport}
          fitView
          attributionPosition="bottom-left"
        >
          {/* <MiniMap
          nodeStrokeColor={(n) => {
            if (n.type === "input") return "#0041d0";
            if (n.type === "selectorNode") return bgColor;
            if (n.type === "output") return "#ff0072";
          }}
          nodeColor={(n) => {
            if (n.type === "selectorNode") return bgColor;
            return "#fff";
          }}
        />
        <Controls /> */}
        </ReactFlow>
      </div>
    </>
  );
};

export default WeFlow;
